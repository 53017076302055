<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-card
          border-variant="primary"
          header="Datos personales"
          header-bg-variant="primary"
          header-text-variant="white"
        >
          <b-card-text>
            <div class="row">
              <div class="col-md-6">
                <label class="typo__label">Seleccione especialidad</label>
                <multiselect
                  v-model="form.specialty"
                  deselect-label="No puedes remover este valor"
                  track-by="id"
                  label="name"
                  placeholder="Selecciona uno"
                  :options="specialties"
                  :searchable="true"
                  :allow-empty="false"
                  @select="selectSpecialty"
                >
                </multiselect>
              </div>
              <div class="col-md-6">
                <label class="typo__label"
                  >Seleccione profesional de la salud</label
                >
                <multiselect
                  v-model="form.doctor"
                  deselect-label="No puedes remover este valor"
                  track-by="id"
                  label="full_name"
                  placeholder="Selecciona uno"
                  :options="doctors"
                  :searchable="true"
                  :allow-empty="false"
                >
                </multiselect>
              </div>
              <div class="col-md-6">
                <label class="typo__label">Seleccione fecha y hora</label>
                <b-input type="datetime-local" v-model="form.hour_of_service" />
              </div>
              <div class="col-md-6">
                <label class="typo__label">Ingrese precio (S/)</label>
                <b-input type="number" step="0.1" v-model="form.price" />
              </div>
            </div>
          </b-card-text>
          <b-card-footer>
            <b-button variant="success" @click="saveAppointment"
              >Guardar
            </b-button>
          </b-card-footer>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect
  },
  props: ["person"],
  data() {
    return {
      form: {
        specialty: null,
        doctor: null,
        hour_of_service: "2021-01-01T19:30",
        price: 18.0
      },
      specialties: [],
      doctors: []
    };
  },
  methods: {
    getSpecialties() {
      ApiService.get("specialty", "?is_auxiliary=0").then(res => {
        this.specialties = res.data;
      });
    },

    selectSpecialty(selectedOption) {
      console.log(selectedOption);

      this.form.doctor = null;
      this.doctors = [];

      ApiService.get(
        "user-specialty/search/doctor",
        `?specialty=${selectedOption.id}`
      ).then(res => {
        this.doctors = res.data;
      });
    },
    saveAppointment() {
      let appointment = this.form;
      appointment.doctor = this.form.doctor.id;
      appointment.specialty = this.form.specialty.id;
      appointment.person = this.$route.query.person;
      ApiService.post(
        `appointment/?pos_key=${localStorage.pos_key}`,
        appointment
      ).then(res => {
        this.makeToast("success");
        ApiService.get(`appointment/${res.data.id}/print`).then(res2 => {
          const ruc = process.env.VUE_APP_ENTERPRISE_RUC;
          this.axios.post(`http://localhost:3030/${ruc}/`, res2.data);
          this.axios.post(`http://localhost:3030/${ruc}/`, res2.data);
          setTimeout(() => {
            this.$router.push({ name: "clinic_person_search" });
          }, 1000);
        });
      });
    },

    makeToast(variant = null) {
      this.$bvToast.toast("Éxito al guardar", {
        title: `Todo bien`,
        variant: variant,
        solid: true
      });
    },
    getCurrentDateTimeFormat() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();
      let hour = String(today.getHours()).padStart(2, "0");
      let minutes = String(today.getMinutes()).padStart(2, "0");

      let formatted = yyyy + "-" + mm + "-" + dd + "T" + hour + ":" + minutes;
      console.log(formatted);
      this.form.hour_of_service = formatted;
    }
  },
  mounted() {
    this.getCurrentDateTimeFormat();
    this.getSpecialties();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Agendar cita" }]);
  }
};
</script>

<style scoped>
.card-header {
  padding: 1rem 2.25rem;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
